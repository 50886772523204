import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { seoHead, isSafariLess16 } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfLegal {
          legalSections {
            legalContent
            legalTitle
          }
        }
        featuredImage {
          node {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
        }
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
    }
  }
`

const LegalTemplate = ({ data }) => {
  //  set up post data
  const page = data.wpgraphql.page

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const altText = page.featuredImage.node.altText
  const imgUri = page.featuredImage.node.mediaDetails.file
  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

  theImage.resize(fill().width(1680).height(350))
  theImage.quality("70")
  theImage.format("auto")

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <Layout>
        <div className="darkgrey_bg pt-6">
          <div id="hide-row-when-nav-open" className="container">
            <div className="row mt-5">
              <div className="col-12 color-white animated-headline mt-4 mt-lg-0">
                <div className="animated-headline-wrap mb-5">
                  <h1 className="lower new_style">
                    <span
                      className={`new_style_addit ${
                        isSafariV16 ? "safari_16" : ""
                      }`}
                      dangerouslySetInnerHTML={{ __html: page.title }}
                    />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <AdvancedImage cldImg={theImage} alt={altText} className={`w-100`} />
        </div>
        <div className="mt-6 mb-6">
          {page.cfLegal.legalSections.map((section, index) => (
            <div key={index} className="container mb-4 mb-lg-5">
              <div className="row">
                <div className="col-12 col-lg-4 pe-0 pe-lg-5">
                  <h3 className="mb-3 lower">{section.legalTitle}</h3>
                </div>
                <div className="col-12 col-lg-8">
                  <div
                    dangerouslySetInnerHTML={{ __html: section.legalContent }}
                  />
                  {page.cfLegal.legalSections.length > index + 1 && (
                    <hr className="mt-4 mt-lg-5 mb-0"></hr>
                  )}
                </div>
              </div>
            </div>
          ))}

          {/* Contains the cookieyes generated cookie list */}
          {page.id == "cG9zdDoyNTk2OQ==" && (
            <div className="container mb-4 mb-lg-5">
              <div className="row">
                <div className="col-12 col-lg-4 pe-0 pe-lg-5">
                  <h3 className="mb-3 mt-5 lower">Cookie List</h3>
                </div>
                <div className="col-12 col-lg-8">
                  <hr className="mt-0 mb-5"></hr>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        '<div class="cky-audit-table-element war-audit"></div>',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export default LegalTemplate

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
